let openedModalId = false;

export async function modal(id, type = null) {
  const modal = document.getElementById(id);
  const backdrop = document.getElementById("modalBackdrop");

  if (modal) {
    if (id === openedModalId) {
      modal.classList.remove("show");
      backdrop.classList.remove("show");
      openedModalId = false;
      document.documentElement.style.overflow = "auto";
    } else {
      if (openedModalId && !type) {
        const currentModal = document.getElementById(openedModalId);
        currentModal.classList.remove("show");
      }
      modal.classList.add("show");
      backdrop.classList.add("show");
      openedModalId = id;
      document.documentElement.style.overflow = "hidden";
    }
  } else {
    openedModalId = "";
    document.documentElement.style.overflow = "auto";
  }
}
