import data from "./data";
export default {
  state: {
    user: {
      token: "",
      progress: 0,
    },
    referrals: {},
    bustCount: {},
    auth: false,
    modal: false,
  },
  mutations: {
    SET_RUN(state, newData) {
      if (newData.user) {
        state.auth = true;
      }
    },
    UPDATE_DATA(state, payload) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    UPDATE_USER(state, payload) {
      if (!state.auth) state.auth = true;
      Object.keys(payload).forEach((key) => {
        state.user[key] = payload[key];
      });
    },
    UPDATE_CLAIM(state, t = 0) {
      if (t == 0) {
        state.user.gold += state.user.mine;
        state.user.mine = 0;
      }

      while (true) {
        let gold = state.user.gold ?? 0;
        let pr = (gold / data.rang[Math.min(state.user.lvl + 1, 9)].xp) * 100;
        state.user.progressLvl = pr < 0 ? 0 : pr;

        if (pr >= 100 && state.user.lvl < 9) {
          state.user.lvl += 1;
        } else {
          break;
        }
      }
    },
    UPDATE_MINE(state) {
      // Вычисляем доход за одну секунду
      const incomePerSecond = state.user.per / 3600;
      state.time += 1;
      let diffInSeconds = Math.floor(state.time - state.user.who); // разница во времени в секундах
      const currentBalance = Math.floor(diffInSeconds * incomePerSecond);

      // Обновляем баланс
      if (currentBalance > 0) {
        state.user.mine += currentBalance;
        state.user.who = state.time;
      }
    },
    UPDATE_UPGRADE(state, id) {
      if (state.user.playerData[id] < 5) {
        state.user.playerData[id] += 1;
        state.user.gold -= data.playerPrice[id][state.user.playerData[id]];
      }
    },
  },
  actions: {
    async run({ commit }, newData) {
      commit("SET_RUN", newData);
      commit("UPDATE_DATA", newData);
      commit("UPDATE_MINE");
      commit("UPDATE_CLAIM", 1);
      setInterval(() => {
        commit("UPDATE_MINE");
      }, 1000);
    },
    async updateUser({ commit }, newData) {
      commit("UPDATE_USER", newData);
    },
    async update({ commit }, newData) {
      commit("UPDATE_DATA", newData);
    },
    async claim({ commit }) {
      commit("UPDATE_CLAIM");
    },
    async upgrade({ commit }, id) {
      commit("UPDATE_UPGRADE", id);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    app(state) {
      let result = {};
      result.data = data;
      result.time = state.time;
      result.auth = state.auth;
      result.top = state.top;
      result.bust = state.bust;
      result.bustCount = state.bustCount;
      result.quests = state.quests;
      result.referrals = state.referrals;
      result.perk = state.perk;
      result.modal = state.modal;
      return result;
    },
  },
};
