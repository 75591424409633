<template>
    <div class="sidebar">
        <router-link to="/" class="link" :class="{'active':this.$route.name=='home'}">
            <i class="icon bg-white x30 i-earn"></i>
            <div class="">{{ $t('Earn') }}</div>
        </router-link>
        <router-link to="/invite" class="link" :class="{'active':this.$route.name=='invite'}">
            <i class="icon bg-white x30 i-invite"></i>
            <div class="">{{ $t('invite') }}</div>
        </router-link>
        <router-link to="/player" class="link" :class="{'active':this.$route.name=='player'}">
            <i class="icon bg-white x30 i-player"></i>
            <div class="">{{ $t('Player') }}</div>
        </router-link>
        <router-link to="/quests" class="link" :class="{'active':this.$route.name=='quests'}">
            <i class="icon bg-white x30 i-quests"></i>
            <div class="">{{ $t('Quests') }}</div>
        </router-link>
        <router-link to="/shop" class="link" :class="{'active':this.$route.name=='shop'}">
            <i class="icon x30 i-shop bg-white"></i>
            <div class="">{{ $t('Shop') }}</div>
        </router-link>
        <router-link to="/top" class="link" :class="{'active':this.$route.name=='top'}">
            <i class="icon bg-white x30 i-top"></i>
            <div class="">{{ $t('Top') }}</div>
        </router-link>
    </div>

</template>

<script>

export default {
    data() {
        return {
            date: new Date(),
        };
    },
    methods: {
        lang(lang) {
            this.$cookies.set("lang", lang, {
                path: "/",
                secure: true,
                sameSite: "strict",
            });

            if (Object.keys(this.$i18n.messages[lang]).length > 0) {
                this.$i18n.locale = lang;
            } else {
                this.$i18n.locale = "en";
                this.$i18n.silentFallbackWarn = true;
            }
            this.$modal("langModal");
        }
    },
};
</script>
