import { mapGetters } from "vuex";

let checkUserCalled = false;

export const appMixin = {
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["app"]),
  },
  created() {
    if (!checkUserCalled && window.Telegram) {
    // if (!checkUserCalled) {
      this.checkUser();
      checkUserCalled = true;
    }
  },
  methods: {
    async checkUser() {
      const initData = window.Telegram.WebApp.initData;
      const data = await this.$post(
        "/auth"
        , { initData }
      );
      if (data) {
        if (data.error) {
          this.$toast.show(data.error, { type: "error" });
        } else {
          this.$store.dispatch("run", data);
        }
      }
    },
  },
};
