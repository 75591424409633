<template>
  <div class="navbar">
    <div>
      <router-link to="/profile">
        <div class="card nav">
          <div class="d-flex"><i class="icon i-profile bg-white x34 br-50"></i></div>
          <div class="pl-3">
            <div class="fs-18px fw-800">{{ user.first.slice(0, 8) }} {{ user.last.charAt(0) }}</div>
            <div class="text-light">{{ app.data.rang[user.lvl].name }}</div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="d-flex">
      <router-link to="/wallet" class="mr-3">
        <div class="card nav"><i class="icon i-wallet x34 br-50"></i></div>
      </router-link>
      <a @click="gaidNext('next')">
        <div class="card nav"><i class="icon i-info x26 br-50"></i></div>
      </a>
    </div>
  </div>

  <div class="gaid" :class="{ show: gaid > 0 }">
    <div class="gaid-dialog">
      <div class="gaid-content">
        <img v-show="gaid == 1" src="../../assets/images/gaid/1.webp" />
        <img v-show="gaid == 2" src="../../assets/images/gaid/2.webp" />
        <img v-show="gaid == 3" src="../../assets/images/gaid/3.webp" />
        <img v-show="gaid == 4" src="../../assets/images/gaid/4.webp" />

        <div style="position: absolute; bottom: 0px; width: 100%">
          <div class="gaidProgress p-2 m-auto mt-1">
            <div class="line" :class="{ active: gaid == 1 }"></div>
            <div class="line" :class="{ active: gaid == 2 }"></div>
            <div class="line" :class="{ active: gaid == 3 }"></div>
            <div class="line" :class="{ active: gaid == 4 }"></div>
          </div>
          <div class="d-flex p-2 mt-5">
            <a @click="gaidNext('skip')" class="btn skip m-2" style="width: 100px">{{ $t("Skip") }}</a>
            <a @click="gaidNext('next')" class="btn claim text-up text-white m-2">
              <b>{{ $t("Next") }}</b>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal :id="'joinModal'" style="p-0">
    <div class="text-center p-5">
      <h1 class="m-2 text-up fs-45px">{{ $t("Follow telegram") }}</h1>
      <h4 class="mt-5">
        {{ $t("Follow Telegram to stay up to date on all news, Airdrops, events, influence the future of the game through votes and polls, spread the word and get exclusive rewards!") }}
      </h4>
      <h4 class="mt-2 fw-700">{{ $t("Don't miss the latest news about") }}</h4>
      <h4 class="mt-2 fw-700">Mirrion Beyond!</h4>
    </div>
    <div class="col1 text-center mt-6">
      <a href="https://t.me/MirrionBeyond" target="_blank" class="btn claim text-up p-4">{{ $t("Earn more") }}</a>
    </div>
  </Modal>
</template>

<script>
export default {
  data() {
    return {
      gaid: 0,
    };
  },
  mounted() {
    window.addEventListener("mousedown", this.handleMouseDown);
    if (Math.floor(Math.random() * 100) + 1 > 60) {
      this.$modal("joinModal");
    }
  },
  methods: {
    async gaidNext(key) {
      if (key == "next") this.gaid += 1;
      if (key == "skip" || this.gaid == 5) this.gaid = 0;

      if (this.user.gaid == 0) {
        const response = await this.$post("/main", { act: "gaid" });
        this.$store.dispatch("updateUser", { gaid: 1 });
      }
    },
    lang(lang) {
      this.$cookies.set("lang", lang, {
        path: "/",
        secure: true,
        sameSite: "strict",
      });

      if (Object.keys(this.$i18n.messages[lang]).length > 0) {
        this.$i18n.locale = lang;
      } else {
        this.$i18n.locale = "en";
        this.$i18n.silentFallbackWarn = true;
      }
      this.$modal("langModal");
    },
    async logOut() {
      this.$router.push("/");
      this.$cookies.set("token", "");
      this.$store.dispatch("userOut");
    },
  },
};
</script>
