import axios from "axios";
import store from "../store";

const baseUrl = "https://" + window.location.hostname + "/api";
// const baseUrl = "http://" + window.location.hostname + ":3002/api";

export async function post(event, param={}) {
  try {
    let data;
    let token = store.getters.user.token || '';

    if (param == "submit") {
      event.preventDefault();
      const form = event.target.closest("form");

      if (form) {
        const formData = new FormData(form);
        if (token) formData.append("token", token);

        const jsonData = {};
        formData.forEach((value, key) => {
          jsonData[key] = value;
        });

        const submitBtn = form.querySelector("[type=submit]");
        submitBtn.disabled = true;
        let url = form.id;
        data = await go(url, jsonData, "application/json");
        submitBtn.disabled = false;
      }
    } else {
      param.token = token;
      let jsonData = JSON.stringify(param);

      data = await go(event, jsonData, "application/json");
    }

    if (data && data.user) {
      store.dispatch("userData", data.user);
    }

    return data;
  } catch (error) {
    // Используем Vue Toast для отображения ошибки
    if (this.$toast) {
      this.$toast.show(`Error: ${error.message || error}`, { type: "error" });
    } else {
      console.error("Error:", error);
    }
    return;
  }
}

async function go(url, body, type) {
  try {
    const response = await axios.post(baseUrl + url, body, {
      headers: {
        ...(type && { "Content-Type": type }),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return { error: error.message || "An error occurred" };
  }
}
