<template>
  <div v-if="app.auth && load" class="container">
    <div>
      <toastr />
      <Navbar />
    </div>
    <div>
      <router-view />
    </div>
    <Sidebar />
  </div>
  <div v-else class="loading-screen">
    Loading {{ progress }}%
    <div style="display: none">
      <img v-for="src in sources" :src="src" :key="src" />
    </div>
  </div>

  <div class="modal-backdrop fade" id="modalBackdrop"></div>
</template>

<script>
export default {
  data() {
    return {
      progress: 0,
      load: false,
    };
  },
  mounted() {
    this.preloadImages();
  },
  methods: {
    preloadImages() {
      // Используем require.context для получения всех изображений
      const requireContext = require.context("@/assets", true, /\.(png|jpe?g|svg|webp|gif|bmp|tiff)$/);
      const imagePaths = requireContext.keys();
      const totalImages = imagePaths.length;
      let loadedImages = 0;

      imagePaths.forEach((imagePath) => {
        const img = new Image();
        img.src = requireContext(imagePath);
        img.onload = img.onerror = () => {
          loadedImages++;
          this.progress = Math.round((loadedImages / totalImages) * 100);

          if (loadedImages === totalImages) {
            this.load = true;
          }
        };
      });
    },
  },
};
</script>

<style scoped>
@import "assets/css/bootstrap-icons/bootstrap-icons.css";
@import "assets/fonts/poppins/poppins.css";
@import "assets/css/root.css";
@import "assets/css/style.css";
@import "assets/css/icon.css";
@import "assets/css/slider.css";
@import "assets/css/modal.css";

/* Добавьте стили для загрузочного экрана */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
}
</style>
